import { FileOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Card,
  Col,
  Modal,
  Progress,
  Row,
  Statistic,
  Typography,
  notification,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import styled from "styled-components";
import imageCover from "../../assets/images/patterns/welcome-profile.png";
import animationLoading from "../../components/lotties/loading";
import { removeTrailingZeros } from "../../helpers/formatNumbers";
import { moneyFormat } from "../../helpers/money";
import {
  useApportMonthFetch,
  useMyApportFetch,
  usePercentMonthFetch,
} from "../../hooks/apports/useApports.query";
import {
  useGainFetch,
  useProfileFetch,
} from "../../hooks/profile/useProfile.query";
import { useTotalWithdrawFetch } from "../../hooks/withdraw/useWithdraw.query";
import Apport from "../Apport";
import Direct from "./Direct";
import Matrix from "./Matrix";

const SctyledCard = styled.div`
  width: 100%;
  height: 250px;
  background-color: #8a2be298;
  background-image: url(${imageCover});
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  background-position: center;
  border-radius: 15px;
  padding: 15px;
  position: relative;
  h1 {
    z-index: 5;
    position: absolute;
  }
  h3 {
    position: absolute;
    z-index: 5;
    color: #ffffff99;
    top: 50px;
  }
`;
const StyledOverlay = styled.div`
  border-radius: 15px;
  backdrop-filter: blur(9px);
  width: 100%;
  height: 100%;
  /* background-color: #00000053; */
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
`;
const StyledeLink = styled(Link)`
  color: white;
  bottom: 10px;
  position: absolute;
  font-size: 1.1rem;
  z-index: 5;
`;

var getDate = new Date();
var year = getDate.getFullYear();
var month = String(getDate.getMonth() + 1).padStart(2, "0");

const Dashboard = () => {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [openModalDeposit, setOpenModalDeposit] = useState(false);

  const { data: profileData, isLoading: loadingProfile } = useProfileFetch();
  const profile = profileData?.data;
  const { data: myApport, isLoading: loadingMyApports } = useMyApportFetch(
    profile?.id
  );
  const { data: gain, isLoading: loadingGain } = useGainFetch(profile?.id);
  var dataSearch = {
    date: `${year}-${month}`,
    profile: profile?.id,
  };
  const { data: dataApport, isLoading: loadingApports } =
    useApportMonthFetch(dataSearch);
  const { data: percentFetch, isLoading: loadingPercent } =
    usePercentMonthFetch();

  const { data: totalWithdrawData, isLoading: loadingTotalWithdraw } =
    useTotalWithdrawFetch();

  const { Title } = Typography;

  const handleDeposit = () => {
    setOpenModalDeposit(true);
  };

  const requestDeposit = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/apport/aporte-provisorio`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(requestDeposit, {
    onSuccess: (data) => {
      console.log("sd", data);
      if (data.status === 200) {
        api.success({
          message: "Sucesso.",
          description: "Deposito realizado.",
        });
        setTimeout(1500);
        queryClient.invalidateQueries("my-apports");
        setOpenModalDeposit(false);
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });
  const handleSubmitDeposit = (e) => {
    mutateAsync(e);
  };

  const count = [
    {
      today: "Minha Posição ",
      title: profile?.profile?.position,
      // persent: "+30%",
      icon: "a",
      bnb: "bnb2",
      loading: loadingProfile,
    },
    {
      today: "Afiliados",
      title: profile?.referral?.length,
      // persent: "+20%",
      icon: "v",
      bnb: "bnb2",
      loading: loadingProfile,
    },
    {
      today: "Meu investimento",
      title: moneyFormat(myApport?.data),
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      loading: loadingMyApports,
    },
    {
      today: "Ganhos de afiliados mês atual",
      title: moneyFormat(dataApport?.data?.total),
      persent: "10%",
      link: "/ganhos",
      textLink: "Detalhes",
      bnb: "bnb2",
      loading: loadingApports,
    },
    {
      today: "Ganhos de Indicação",
      title: moneyFormat(gain?.data),
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      loading: loadingGain,
    },
    {
      today: "Meus saques",
      title: moneyFormat(totalWithdrawData?.data),
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      loading: loadingTotalWithdraw,
    },
    {
      today: "Rentabilidade ultimo mes",
      title: removeTrailingZeros(percentFetch?.data[0]?.percentage),
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      loading: loadingPercent,
    },
    {
      today: "Realizar um Deposito",
      title: (
        <>
          <Button
            style={{ width: "100%", background: "#18ff71", color: "white" }}
            type=""
            onClick={handleDeposit}
          >
            <span style={{ color: "rgb(18 16 16)" }}>Despositar</span>
          </Button>
        </>
      ),
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      loading: false,
    },
  ];
  console.log(">", profile);
  return (
    <div className="layout-content">
      {contextHolder}
      <Row gutter={[24, 5]}>
        <Col className="mb-24" xs={24}>
          <SctyledCard>
            <StyledOverlay />
            <h1>Bem vindo de volta!</h1>
            <h3>Prazer em vê-lo, {profile?.profile?.full_name}!</h3>
            <StyledeLink to="perfil">Ver meu perfil</StyledeLink>
          </SctyledCard>
        </Col>
      </Row>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox dark">
              {c.loading ? (
                <div
                  style={{
                    height: "80px",
                  }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationLoading,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={50}
                    width={50}
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      top: "50%",
                    }}
                  />
                </div>
              ) : (
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={24}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                      </Title>
                      {c.link ? (
                        <Link to={c.link}>{c.textLink}</Link>
                      ) : (
                        <FileOutlined
                          style={{ fontSize: "16px", color: "#ffffff00" }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={12} lg={12} xl={14} className="mb-24">
          <Card bordered={false} className="criclebox h-full dark">
            <Direct data={profile?.referral} />
          </Card>
        </Col>
        {/* <Col xs={24} sm={24} md={12} lg={12} xl={10} className="mb-24">
          <Card bordered={false} className="criclebox h-full dark">
            <Matrix />
          </Card>
        </Col> */}
      </Row>

      <Row gutter={[24, 0]}></Row>

      {/* <Row gutter={[24, 0]}>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card bordered={false} className="criclebox dark" title="Perfil">
            <Progress type="dashboard" percent={75} status="active" />
          </Card>
        </Col>
        <Col xs={24} sm={24} md={6} lg={6} xl={6}>
          <Card
            bordered={false}
            className="criclebox dark"
            title="Indicados diretos"
            style={{
              height: "240px",
            }}
          >
            <Statistic value={2} suffix="/5" />
          </Card>
        </Col>
      </Row> */}

      <Modal
        title="Digite o valor que deseja depositar"
        centered
        open={openModalDeposit}
        onCancel={() => setOpenModalDeposit(false)}
        footer={false}
        destroyOnClose={true}
      >
        <Apport onSubmit={handleSubmitDeposit} isLoading={isLoading} />
      </Modal>
    </div>
  );
};

export default Dashboard;
