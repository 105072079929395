import { Col, Row, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { sumAmount } from "../../helpers/sumAmount";
import { useAllApportFetch } from "../../hooks/apports/useApports.query";
import { useProfileFetch } from "../../hooks/profile/useProfile.query";

const SctyledCard = styled.div`
  width: 100%;
  height: 100px;
  background-color: #2b4ce297;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  background-position: center;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  h1 {
    z-index: 5;
    position: absolute;
  }
  h3 {
    position: absolute;
    z-index: 5;
    color: #ffffff99;
    top: 50px;
  }
`;
const StyledOverlay = styled.div`
  border-radius: 15px;
  backdrop-filter: blur(9px);
  width: 100%;
  height: 100%;
  background-color: #00000053;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
`;
const StyledeLink = styled(Link)`
  color: white;
  bottom: 10px;
  position: absolute;
  font-size: 1.1rem;
  z-index: 5;
`;

const columns = [
  {
    title: "Data",
    dataIndex: "date",
    key: "date",
  },
  {
    title: "Valor Total",
    dataIndex: "amount",
    key: "amount",
    render: (amount) =>
      sumAmount([
        parseFloat(amount.amount_first_line),
        parseFloat(amount.amount_second_line),
        parseFloat(amount.amount_tirth_line),
        parseFloat(amount.amount_fourth_line),
        parseFloat(amount.amount_fifth_line),
      ]),
  },
  {
    title: "Valor Primeira Linha",
    dataIndex: "first_line",
    key: "first_line",
  },
  {
    title: "Valor Segunda Linha",
    dataIndex: "second_line",
    key: "second_line",
  },
  {
    title: "Valor Terceira Linha",
    dataIndex: "third_line",
    key: "third_line",
  },
  {
    title: "Valor Quarta Linha",
    dataIndex: "fourth_line",
    key: "fourth_line",
  },
  {
    title: "Valor Quinta Linha",
    dataIndex: "fifth_line",
    key: "fifth_line",
  },
  {
    title: "Porcentagem",
    dataIndex: "percentage",
    key: "percentage",
  },
];

const Gains = () => {
  const { data: dataProfile } = useProfileFetch();
  const profile = dataProfile?.data;
  const { data: dataAllApports } = useAllApportFetch(profile?.id);
  const apports = dataAllApports?.data;

  const dataSource = apports?.map((apport, index) => {
    return {
      key: index,
      date: apport.date,
      amount: apport,
      first_line: apport.amount_first_line,
      second_line: apport.amount_second_line,
      third_line: apport.amount_tirth_line,
      fourth_line: apport.amount_fourth_line,
      fifth_line: apport.amount_fifth_line,
      percentage: "apport.percentage",
    };
  });

  return (
    <>
      <Row gutter={[24, 5]}>
        <Col className="mb-24" xs={24}>
          <SctyledCard>
            <StyledOverlay />
            <h1>Aqui está os seus ganhos residuais detalhados.</h1>
          </SctyledCard>
        </Col>
      </Row>
      <Row gutter={[24, 5]}>
        <Col className="mb-24" xs={24}></Col>
      </Row>
      <Table dataSource={dataSource} columns={columns} />
    </>
  );
};

export default Gains;
