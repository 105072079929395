import { FileOutlined } from "@ant-design/icons";
import {
  Card,
  Col,
  Collapse,
  Popover,
  Row,
  Table,
  Tag,
  Typography,
} from "antd";
import React from "react";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import styled from "styled-components";
import animationLoading from "../../components/lotties/loading";
import { moneyFormat } from "../../helpers/money";
import { typeWithdraw } from "../../helpers/typeWithdraw";
import {
  useAvailableWithdrawFetch,
  useWithdrawFetch,
  useWithdrawPendingFetch,
} from "../../hooks/withdraw/useWithdraw.query";
import StepsWithdraw from "./StepsWithdraw";

const SctyledCard = styled.div`
  width: 100%;
  height: auto;
  background-color: #2b4ce297;
  background-repeat: no-repeat;
  object-fit: cover;
  background-size: 100%;
  background-position: center;
  border-radius: 15px;
  padding: 20px;
  position: relative;
  color: #efefef;
  h1 {
    z-index: 5;
  }
`;

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      <span>
        step {index} status: {status}
      </span>
    }
  >
    {dot}
  </Popover>
);
const description = "You can hover on the dot.";

const Withdraw = () => {
  const { Title } = Typography;
  const { data } = useAvailableWithdrawFetch();
  const { data: withdrawData } = useWithdrawFetch();
  const withdraws = withdrawData?.data;
  const { data: withdrawPending } = useWithdrawPendingFetch();

  const count = [
    {
      today: "Matriz",
      title: moneyFormat(data?.data?.matrix),
      gain: "",
      minus: "1",
      // persent: "+30%",
      icon: "a",
      bnb: "bnb2",
      // loading: loadingProfile,
    },
    {
      today: "Indicação",
      title: moneyFormat(data?.data?.indication),
      gain: "",
      minus: "1",
      // persent: "+20%",
      icon: "v",
      bnb: "bnb2",
      // loading: loadingProfile,
    },
    {
      today: "Aporte",
      title: moneyFormat(data?.data?.apport),
      gain: "",
      minus: "1",
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      // loading: loadingMyApports,
    },
    {
      today: "Saque Pendente",
      title: "withdraw",
      gain: "",
      minus: "1",
      // persent: "10%",
      icon: "k",
      bnb: "bnb2",
      // loading: loadingMyApports,
    },
  ];

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      // render: (text) => <a>{text}</a>,
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status === 0 && <Tag color="magenta">Pendente</Tag>}
          {status === 1 && <Tag color="green">Pendente</Tag>}
          {status === 2 && <Tag color="green">Pendente</Tag>}
        </>
      ),
    },
  ];

  const datap = withdraws?.map((withdraw) => {
    return {
      key: withdraw.id,
      id: withdraw.payment_id,
      date: withdraw.payment_data,
      amount: moneyFormat(withdraw.amount),
      type: typeWithdraw(withdraw.payment_type),
      status: withdraw.status,
    };
  });
  return (
    <div className="layout-content">
      <Row gutter={[24, 5]}>
        <Col className="mb-24" xs={24}>
          <SctyledCard>
            <h1>
              Aqui estão seus saldos dentro do Esphera Trading. <br />{" "}
              <small>Saque de matriz disponível entre os dias 5 e 10</small>{" "}
            </h1>
          </SctyledCard>
        </Col>
      </Row>
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={6}
            xl={6}
            className="mb-24"
          >
            <Card bordered={false} className="criclebox dark">
              {c.loading ? (
                <div
                  style={{
                    height: "80px",
                  }}
                >
                  <Lottie
                    options={{
                      loop: true,
                      autoplay: true,
                      animationData: animationLoading,
                      rendererSettings: {
                        preserveAspectRatio: "xMidYMid slice",
                      },
                    }}
                    height={50}
                    width={50}
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      top: "50%",
                    }}
                  />
                </div>
              ) : (
                <div className="number">
                  <Row align="middle" gutter={[24, 0]}>
                    <Col xs={24}>
                      <span>{c.today}</span>
                      <Title level={3}>
                        {c.title} <small className={c.bnb}>{c.persent}</small>
                        <small className="bnbminus">{c.minus}</small>
                        <small className={c.bnb}>{c.gain}</small>
                      </Title>
                      {c.link ? (
                        <Link to={c.link}>{c.textoLink}</Link>
                      ) : (
                        <FileOutlined
                          style={{ fontSize: "16px", color: "#ffffff00" }}
                        />
                      )}
                    </Col>
                  </Row>
                </div>
              )}
            </Card>
          </Col>
        ))}
      </Row>

      <Row gutter={[24, 5]}>
        <Col className="mb-24" xs={24}>
          <Collapse
            items={[
              {
                key: "1",
                label: "Meus saques",
                children: (
                  <>
                    <br />
                    <br />
                    <br />
                    <Table columns={columns} dataSource={datap} />
                  </>
                ),
              },
              {
                key: "2",
                label: "Solicitar Saque",
                children: (
                  <>
                    <br />
                    <br />
                    <br />
                    <StepsWithdraw
                      amountIndication={moneyFormat(data?.data?.indication)}
                      amountMatrix={moneyFormat(data?.data?.matrix)}
                    />
                  </>
                ),
              },
            ]}
            bordered={false}
          />
          <br />
          <br />
        </Col>
      </Row>
    </div>
  );
};

export default Withdraw;
