export const removeTrailingZeros = (num) => {
  if (!isNaN || num === null) {
    return "";
  }

  if (num) {
    let numString = num.toString().replace(/0*$/, "");
    return parseFloat(numString) + "%";
  }
};

export const stripZeros = (str) => {
  return str.replace(/(^0+(?=\d))|(,?0+$)/g, "");
};
