import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAportMonth = async ({ date, profile }) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/apport/gains-month?id=${profile}&date=${date}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getAllAport = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/apport/gains-year?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getMyAport = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/apport/view?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getPercentMonth = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/percent-month`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useApportMonthFetch(datasearch) {
  const { profile } = datasearch;
  return useQuery(["aport-month"], () => getAportMonth(datasearch), {
    enabled: !!profile,
    retry: 5,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useAllApportFetch(id) {
  return useQuery(["all-apport", id], async () => await getAllAport(id), {
    enabled: !!id,
    retry: 5,
    cacheTime: 1000 * 60 * 60 * 24,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useMyApportFetch(id) {
  return useQuery(["my-apports", id], async () => await getMyAport(id), {
    enabled: !!id,
    retry: 5,
    cacheTime: 1000 * 60 * 60 * 24,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function usePercentMonthFetch() {
  return useQuery(["percent-month"], async () => await getPercentMonth(), {
    retry: 5,
    cacheTime: 1000 * 60 * 60 * 24,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}
