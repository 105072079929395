import { QueryClient } from "@tanstack/react-query";
import axios from "axios";

const queryClient = new QueryClient({
  defaultOptions: {
    retry: 3,
  },
});

axios.get("/");

export default queryClient;
