import { useMutation } from "@tanstack/react-query";
import {
  Button,
  Col,
  Descriptions,
  Form,
  Input,
  Popover,
  Radio,
  Result,
  Row,
  Select,
  Steps,
  notification,
  theme,
} from "antd";
import axios from "axios";
import React, { useState } from "react";
import IntlCurrencyInput from "react-intl-currency-input";
import { Success } from "../../components/Icon";
import { moneyFormat } from "../../helpers/money";
import { typeAccount } from "../../helpers/typeAccount";
import { typeWithdraw } from "../../helpers/typeWithdraw";
import { useBankFetchRequest } from "../../hooks/bank/useBank.query";
import ContentCard from "./ContentCard";
import Title from "./Title";

const customDot = (dot, { status, index }) => (
  <Popover
    content={
      ""
      // <span>
      //   step {index} status: {status}
      // </span>
    }
  >
    {dot}
  </Popover>
);

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const StepsWithdraw = ({ amountMatrix, amountIndication }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [formTypeWithdraw, setFormTypeWithdraw] = useState(0);
  const [formAccountWithdraw, setAccountTypeWithdraw] = useState("");
  const [formAmountToWithdraw, setFormAmountToWithdraw] = useState("");

  const [response, setResponse] = useState(null);

  const [confirmationAccount, setConfirmationAccount] = useState({});
  const [disableInputAmount, setDisableInputAmount] = useState(true);
  const [disableReleaseType, setDisableReleaseType] = useState(true);
  const [disableReleaseAccount, setDisableReleaseAccount] = useState(true);

  const { data } = useBankFetchRequest();
  const banks = data?.data;

  const { token } = theme.useToken();
  const [current, setCurrent] = useState(0);

  const onChangeType = (e) => {
    setFormTypeWithdraw(e.target.value);
    setDisableInputAmount(false);
  };

  const handleChangeSelectAccount = (value) => {
    setAccountTypeWithdraw(value);
    setConfirmationAccount(banks.find((el) => el.id === value));
    setDisableReleaseAccount(false);
  };

  const handleChangeAmount = (event, value) => {
    event.preventDefault();

    setDisableReleaseType(false);
    setFormAmountToWithdraw(value);
  };

  const requestWithdraw = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/withdraw/request`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(requestWithdraw, {
    onSuccess: (data) => {
      setResponse(data);
      if (data.status === 200) {
        api.success({
          message: "Sucesso.",
          description: "redirecionando ao painel.",
        });
        setTimeout(1500);
        setCurrent(current + 1);
      }
      if (data.status !== 200) {
        api.error({
          message: "Erro.",
          description: data.message,
        });
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });

  const handleSubmit = () => {
    const data = {
      payment_type: formTypeWithdraw,
      to_send: formAccountWithdraw,
      amount: formAmountToWithdraw,
    };
    mutateAsync(data);
  };

  const steps = [
    {
      title: "Tipo de saque",
      content: (
        <>
          <Title text="Selecione o tipo de saque desejado." />

          <ContentCard>
            <Radio.Group
              onChange={onChangeType}
              value={formTypeWithdraw}
              buttonStyle="solid"
              className="middle"
            >
              <Radio.Button
                value="3"
                className="box"
                style={{ height: "150px" }}
              >
                Matriz <br /> <small>{amountMatrix}</small>
              </Radio.Button>
              <Radio.Button
                value="2"
                className="box"
                style={{ height: "150px" }}
              >
                Indicação <br />
                <small>{amountIndication}</small>
              </Radio.Button>
            </Radio.Group>

            {disableInputAmount ? (
              ""
            ) : (
              <div
                style={{
                  marginTop: "25px",

                  textAlign: "center",
                }}
              >
                <h3 style={{ color: "#fefefe" }}>Digite o valor desejado.</h3>
                <IntlCurrencyInput
                  component={Input}
                  currency="BRL"
                  config={currencyConfig}
                  className="ant-input css-dev-only-do-not-override-1322foz"
                  onChange={handleChangeAmount}
                />
              </div>
            )}

            <br />
            <Button
              disabled={disableReleaseType}
              type="primary"
              onClick={() => next()}
            >
              Proximo
            </Button>
          </ContentCard>
        </>
      ),
    },
    {
      title: "Conta",
      content: (
        <>
          <Title text="Para qual conta devemos enviar o seu saque." />
          <ContentCard>
            <p>
              Selecione uma das contas cadastradas para qual deseja q seja
              enviado o seu saque.
            </p>
            <Select
              defaultValue=""
              style={{ width: "100%" }}
              onChange={handleChangeSelectAccount}
              options={banks?.map((bank) => {
                return { value: bank.id, label: bank.account_name };
              })}
            />

            <br />
            <Row gutter={[10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Button
                  style={{
                    width: "100%",
                    margin: "0 0px",
                  }}
                  onClick={() => prev()}
                >
                  Anterior
                </Button>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Button
                  disabled={disableReleaseAccount}
                  type="primary"
                  onClick={() => next()}
                  style={{ width: "100%" }}
                >
                  Proximo
                </Button>
              </Col>
            </Row>
          </ContentCard>
        </>
      ),
    },
    {
      title: "Confirmação",
      content: (
        <>
          <Title text="Por favor verifique se os dados para a transferencia estao corretos." />
          <ContentCard>
            <Descriptions
              layout="horizontal"
              column={1}
              size="middle"
              bordered
              items={[
                {
                  key: "1",
                  label: "Tipo de saque",
                  children: typeWithdraw(formTypeWithdraw),
                },
                {
                  key: "3",
                  label: "Conta",
                  children: (
                    <>
                      {confirmationAccount?.account_type === 1 ? (
                        <>
                          {typeAccount(confirmationAccount?.account_type)}{" "}
                          <br />
                          {confirmationAccount?.account_name} <br />
                          {confirmationAccount?.type_key} <br />
                          {confirmationAccount?.pix_key}
                        </>
                      ) : (
                        <>
                          {typeAccount(confirmationAccount?.account_type)}{" "}
                          <br />
                          {confirmationAccount?.account_name} <br />
                          {confirmationAccount?.bank_name} <br />
                          {confirmationAccount?.bank_agency} -
                          {confirmationAccount?.account_number} <br />
                          {confirmationAccount?.pix_key}
                        </>
                      )}
                    </>
                  ),
                },
                {
                  key: "4",
                  label: "Valor",
                  children: moneyFormat(formAmountToWithdraw),
                },
              ]}
            />
            <br />
            <Row gutter={[10]}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Button
                  style={{
                    width: "100%",
                    margin: "0 0px",
                  }}
                  onClick={() => prev()}
                >
                  Anterior
                </Button>
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
                <Button
                  style={{ width: "100%" }}
                  disabled={disableReleaseAccount}
                  type="primary"
                  onClick={handleSubmit}
                >
                  Solicitar
                </Button>
              </Col>
            </Row>
          </ContentCard>
        </>
      ),
    },
    // {
    //   title: "Verificação",
    //   content: (
    //     <>
    //       <Title text="Por segurança, vamos verificar sua identidade, preencha o código que enviaremos por email." />
    //       <p>Email para qual vai ser enviado o codigo</p>
    //     </>
    //   ),
    // },
    {
      title: "Sucesso",
      content: (
        <>
          <ContentCard>
            <Result
              icon={<Success size={300} />}
              status="success"
              title="Solicitação de saque feita com sucesso"
              subTitle={`Solicitação numero: ${response?.data?.payment_id} em ${response?.data?.payment_data}. Aguarde para o processamento, e em breve será depositado na conta indicada`}
            />
          </ContentCard>
        </>
      ),
    },
  ];

  const next = () => {
    setCurrent(current + 1);
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const contentStyle = {
    padding: "20px",
    lineHeight: "20px",
    backgroundColor: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: `1px dashed ${token.colorBorder}`,
    marginTop: 16,
  };

  return (
    <>
      <Steps progressDot={customDot} current={current} items={items} />
      <div style={contentStyle}>{steps[current].content}</div>
      {/* <div
        style={{
          marginTop: 24,
        }}
      >
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Proximo
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => message.success("Processing complete!")}
          >
            Done
          </Button>
        )}
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
            }}
            onClick={() => prev()}
          >
            Anterior
          </Button>
        )}
      </div> */}
    </>
  );
};

export default StepsWithdraw;
