import { useMutation } from "@tanstack/react-query";
import { Button, Checkbox, Form, Input, notification } from "antd";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";

const FormJoin = ({ referral }) => {
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const navigate = useNavigate();

  const joinUser = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/signup`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(joinUser, {
    onSuccess: (data) => {
      if (data.status === 201) {
        api.success({
          message: "Cadastro realizado com sucesso.",
          description: "Vamos te redirecionar para a tela de login.",
        });
        setTimeout(3500);
        navigate("/login");
      }
      if (data.status !== 201) {
        api.error({
          message: "Erro.",
          // description: data.message,
        });
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });
  const onFinish = (values) => {
    mutateAsync(values);
  };

  return (
    <>
      {contextHolder}
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        // onFinishFailed={onFinishFailed}
        className="row-col"
        layout="vertical"
      >
        <Form.Item
          name="full_name"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input placeholder="Primeiro Nome" />
        </Form.Item>
        <Form.Item
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="E-mail" />
        </Form.Item>
        <Form.Item
          name="username"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="cpf"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="CPF" />
        </Form.Item>
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "Please input your email!" }]}
        >
          <Input placeholder="Celular" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input placeholder="Senha" />
        </Form.Item>
        <Form.Item
          name="referral"
          rules={[{ required: true, message: "Please input your password!" }]}
          hidden
          initialValue={referral}
        >
          <Input placeholder="Referral" />
        </Form.Item>

        <Form.Item name="accept" valuePropName="checked">
          <Checkbox>
            Eu aceito os{" "}
            <a href="#pablo" className="font-bold text-muted">
              termos e condições
            </a>
          </Checkbox>
        </Form.Item>

        <Form.Item>
          <Button
            style={{ width: "100%" }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Criar conta
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default FormJoin;
