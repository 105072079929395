import { Table } from "antd";
import styled from "styled-components";

export const StyledTable = styled(Table)`
  table thead.ant-table-thead th {
    background-color: #001529;
  }
  table tbody.ant-table-tbody td {
    background-color: #001529;
  }
  table tbody.ant-table-tbody tr:nth-child(odd) {
    background-color: #0c253a;
  }
  table tbody.ant-table-tbody .ant-table-row {
    &:hover .ant-table-cell {
      background-color: #0c253d;
    }
  }
  table tr td {
    background-color: antiquewhite;
  }
`;
