import React from "react";

const Success = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="616.25771"
        height="629"
        viewBox="0 0 616.25771 629"
      >
        <path
          d="M426.89577,375.50218c-20.78511-27.81539-58.82676-34.52842-77.73048-20.40256s-23.24612,52.51044-2.461,80.32582c19.23919,25.7466,54.09581,32.76833,73.29472,23.12236a2.41113,2.41113,0,0,0-.01125.91405l1.08241,5.35123a2.44166,2.44166,0,0,0,3.89636,1.44l9.16592-7.16078a2.44171,2.44171,0,0,0-.45423-4.12917l-4.93052-2.34493a2.41689,2.41689,0,0,0-.54253-.17037C443.00373,436.80267,446.17679,401.30473,426.89577,375.50218Z"
          transform="translate(-291.87115 -135.5)"
          fill="#ccc"
        />
        <path
          d="M381.43087,365.00043a40.1704,40.1704,0,0,1,33.64359,43.91846c-.22342,2.345,3.44053,2.32969,3.66247,0a43.92348,43.92348,0,0,0-36.33243-47.4501C380.0991,361.07691,379.10841,364.60562,381.43087,365.00043Z"
          transform="translate(-291.87115 -135.5)"
          fill="#fff"
        />
        <path
          d="M904.46788,410.32783c11.18429-32.87291-4.7943-68.04273-27.1352-75.64372s-56.45279,10.52662-67.63708,43.39953c-10.35245,30.42794,3.57022,63.14565,22.38347,73.52373a2.41108,2.41108,0,0,0-.76019.50767l-3.801,3.91912a2.44164,2.44164,0,0,0,1.016,4.02776l11.08943,3.50934a2.44172,2.44172,0,0,0,3.14846-2.70991l-.85464-5.39243a2.41667,2.41667,0,0,0-.16633-.54378C863.023,458.28133,894.09294,440.82193,904.46788,410.32783Z"
          transform="translate(-291.87115 -135.5)"
          fill="#ccc"
        />
        <path
          d="M887.41558,366.89325a40.17041,40.17041,0,0,1-17.19235,52.58466c-2.06031,1.142.02452,4.155,2.07135,3.02046a43.92348,43.92348,0,0,0,18.58421-56.79952C889.89813,363.57594,886.42769,364.75462,887.41558,366.89325Z"
          transform="translate(-291.87115 -135.5)"
          fill="#fff"
        />
        <path
          d="M641.717,196.15038c-1.51952-34.69017-29.1811-61.65438-52.757-60.62168s-48.7739,30.31227-47.25439,65.00244c1.4065,32.11,26.26192,57.53635,47.5598,60.37238a2.411,2.411,0,0,0-.52387.74911l-2.11794,5.03206a2.44166,2.44166,0,0,0,2.4096,3.38364l11.60672-.75819a2.44173,2.44173,0,0,0,1.94916-3.66841l-2.75489-4.71374a2.41684,2.41684,0,0,0-.35248-.44623C620.52012,255.88234,643.1266,228.33023,641.717,196.15038Z"
          transform="translate(-291.87115 -135.5)"
          fill="#6c63ff"
        />
        <path
          d="M610.05317,161.87585a40.17042,40.17042,0,0,1,3.0814,55.23792c-1.50481,1.81233,1.532,3.86233,3.027,2.06183a43.92349,43.92349,0,0,0-3.31551-59.67048C611.16128,157.88339,608.356,160.24209,610.05317,161.87585Z"
          transform="translate(-291.87115 -135.5)"
          fill="#fff"
        />
        <circle cx="351.75184" cy="312.48861" r="49.75204" fill="#6c63ff" />
        <path
          d="M641.36144,763.44828H627.641a6.04947,6.04947,0,0,1-6.04248-6.04247v-114.355a6.04948,6.04948,0,0,1,6.04248-6.04248h13.72039a6.04948,6.04948,0,0,1,6.04248,6.04248v114.355A6.04947,6.04947,0,0,1,641.36144,763.44828Z"
          transform="translate(-291.87115 -135.5)"
          fill="#6c63ff"
        />
        <path
          d="M685.311,763.44828h-13.7204a6.04947,6.04947,0,0,1-6.04248-6.04247v-114.355a6.04948,6.04948,0,0,1,6.04248-6.04248H685.311a6.04948,6.04948,0,0,1,6.04247,6.04248v114.355A6.04947,6.04947,0,0,1,685.311,763.44828Z"
          transform="translate(-291.87115 -135.5)"
          fill="#6c63ff"
        />
        <path
          d="M607.0575,544.69684l-5.88573,12.39385a6.04948,6.04948,0,0,1-8.05035,2.86618L489.82281,510.90125a6.04946,6.04946,0,0,1-2.86618-8.05034l5.88573-12.39385a6.04948,6.04948,0,0,1,8.05035-2.86618l103.29861,49.05561A6.04948,6.04948,0,0,1,607.0575,544.69684Z"
          transform="translate(-291.87115 -135.5)"
          fill="#6c63ff"
        />
        <path
          d="M622.6199,551.3401a5.9946,5.9946,0,0,1-.63091,4.1302l-6.63111,12.01094a6.05039,6.05039,0,0,1-8.21079,2.36961l-48.54242-21.39914a6.04839,6.04839,0,0,1-2.36853-8.21008l-.00009-.00044L568.4002,517.4853a6.04157,6.04157,0,0,1,8.21-2.369l43.5971,28.81919c1.41278.78016,1.85005,5.38769,2.29809,6.93832C622.55022,551.02865,622.58786,551.1845,622.6199,551.3401Z"
          transform="translate(-291.87115 -135.5)"
          fill="#2f2e41"
        />
        <path
          d="M682.30291,536.64649l103.29861-49.05561a6.04946,6.04946,0,0,1,8.05034,2.86618l5.88573,12.39385a6.04946,6.04946,0,0,1-2.86618,8.05034L693.3728,559.95687a6.04946,6.04946,0,0,1-8.05034-2.86618l-5.88574-12.39385A6.04949,6.04949,0,0,1,682.30291,536.64649Z"
          transform="translate(-291.87115 -135.5)"
          fill="#6c63ff"
        />
        <path
          d="M663.98885,550.87383c.448-1.55063.8853-6.15816,2.29809-6.93832l43.5971-28.81919a6.04156,6.04156,0,0,1,8.21,2.369l12.16416,22.75589-.00009.00044a6.04839,6.04839,0,0,1-2.36854,8.21008l-48.54242,21.39914a6.05038,6.05038,0,0,1-8.21078-2.36961l-6.63112-12.01094a5.9946,5.9946,0,0,1-.6309-4.1302C663.90636,551.1845,663.944,551.02865,663.98885,550.87383Z"
          transform="translate(-291.87115 -135.5)"
          fill="#2f2e41"
        />
        <path
          d="M637,466.42838l-.05175-1.85922c3.45881-.09623,6.50819-.3132,8.79954-1.98723a5.7152,5.7152,0,0,0,2.21373-4.20958,3.267,3.267,0,0,0-1.07169-2.69079c-1.52105-1.28457-3.96855-.86879-5.75242-.05084l-1.53831.70538,2.95-21.55809,1.842.25237-2.50923,18.33805a6.563,6.563,0,0,1,6.20816.89329,5.08587,5.08587,0,0,1,1.7294,4.176,7.56018,7.56018,0,0,1-2.97494,5.64576C643.90039,466.235,640.07437,466.34214,637,466.42838Z"
          transform="translate(-291.87115 -135.5)"
          fill="#2f2e41"
        />
        <rect
          x="361.62948"
          y="301.11181"
          width="10.0133"
          height="1.85922"
          fill="#2f2e41"
        />
        <rect
          x="330.02268"
          y="301.11181"
          width="10.0133"
          height="1.85922"
          fill="#2f2e41"
        />
        <path
          d="M660.77436,520.99435h2.00589a6.10411,6.10411,0,1,0,0-12.20821H623.714a6.10411,6.10411,0,0,0,0,12.20821h3.66962c-31.35209,14.26417-51.47,66.955-51.47,99.79035,0,38.25155,27.60677,50.59031,68.16629,50.59031s78.712-12.33876,78.712-50.59031C722.79183,587.94935,694.45424,535.25852,660.77436,520.99435Z"
          transform="translate(-291.87115 -135.5)"
          fill="#2f2e41"
        />
        <path
          d="M641.57416,419.57481c-1.21234-3.6838-5.58547-4.81453-8.95119-3.42481-3.94684,1.62966-6.18467,5.6668-9.99652,7.57509-6.85631,3.43241-15.95183,1.58908-21.17908-3.91258-5.71634-6.01642-6.84184-15.40537-3.96511-23.00152,2.97511-7.856,10.35523-13.5421,18.25695-15.85145,10.50009-3.06876,21.76547-.25258,30.86,5.40759a1.848,1.848,0,0,0,2.69007-1.0944c2.50082-9.55493,13.28942-12.33613,21.95775-11.31088a53.07914,53.07914,0,0,1,23.71238,9.463c13.55242,9.455,23.10621,24.85554,24.44454,41.43544a58.6856,58.6856,0,0,1-17.45006,46.33216c-3.05525,2.94814-6.61542,5.26665-9.72978,8.13932-2.83726,2.61708-5.634,6.0598-5.5774,10.13345.1302,9.36669,12.45573,11.95013,19.75025,12.23092a37.29126,37.29126,0,0,0,38.3992-30.83626,38.5414,38.5414,0,0,0,.60191-6.58467c.01087-2.357-3.65158-2.36-3.66247,0a34.5408,34.5408,0,0,1-9.777,23.81406,33.92158,33.92158,0,0,1-24.349,9.97152c-5.82323-.02892-21.6-2.78986-16.31238-12.00145,2.06737-3.60158,5.77738-6.05394,8.97467-8.56029a56.15159,56.15159,0,0,0,9.00473-8.88887,62.39829,62.39829,0,0,0,13.34718-47.6119c-2.36071-16.51547-12.37439-31.43792-25.99674-40.862-12.9697-8.97258-36.94232-16.908-48.2782-1.36239a16.43709,16.43709,0,0,0-2.59126,5.52525l2.69008-1.0944c-8.76233-5.45344-19.20683-8.28229-29.505-6.70452a32.95448,32.95448,0,0,0-22.13974,13.76513,26.95966,26.95966,0,0,0-2.38969,25.34176,22.26366,22.26366,0,0,0,19.95264,13.4727,20.38214,20.38214,0,0,0,12.894-3.94635c2.08647-1.59073,3.6887-3.8427,5.94666-5.19748,1.29317-.77589,4.1999-1.32023,4.836.61249.73343,2.22861,4.2714,1.27421,3.53163-.97362Z"
          transform="translate(-291.87115 -135.5)"
          fill="#2f2e41"
        />
        <path
          d="M724.58579,238.77444a57.62307,57.62307,0,0,1,45.07054,26.69726c1.36448,2.1813,3.14571,4.57838,2.20115,7.26331a4.77535,4.77535,0,0,1-4.948,3.30465c-2.785-.339-4.58214-3.06338-4.92524-5.67387-.38918-2.96122,1.69991-5.28588,4.2791-6.38613,6.30279-2.68851,12.66173,1.46641,17.79576,4.78239,11.69824,7.55577,24.56522,14.38891,38.74031,15.25,13.11515.79662,27.17915-4.34575,34.474-15.71911,1.27626-1.9898-1.89252-3.82848-3.16245-1.84852-7.23473,11.27955-21.86021,15.33068-34.5769,13.58729a67.21864,67.21864,0,0,1-21.6356-7.34549c-3.49529-1.81394-6.88526-3.82475-10.20672-5.93854a65.12892,65.12892,0,0,0-10.01086-5.73884c-5.83742-2.3979-13.565-2.21058-17.64248,3.31024-3.804,5.15056-.86416,13.72537,5.49824,15.17338,6.02963,1.37231,11.31193-4.2985,10.071-10.2278-.63481-3.03305-2.91871-6.0012-4.68657-8.48033A61.2896,61.2896,0,0,0,724.58561,235.112c-2.35588-.11653-2.34973,3.54623,0,3.66247Z"
          transform="translate(-291.87115 -135.5)"
          fill="#ccc"
        />
        <path
          d="M424.856,296.29757a57.62307,57.62307,0,0,1-45.07054-26.69726c-1.36448-2.1813-3.14571-4.57838-2.20115-7.26331a4.77535,4.77535,0,0,1,4.948-3.30465c2.785.339,4.58214,3.06338,4.92524,5.67387.38918,2.96122-1.69991,5.28588-4.2791,6.38613-6.30279,2.68851-12.66173-1.46641-17.79576-4.78239-11.69824-7.55577-24.56522-14.38891-38.74031-15.25-13.11515-.79662-27.17915,4.34575-34.474,15.71911-1.27626,1.9898,1.89252,3.82848,3.16245,1.84852,7.23473-11.27955,21.86021-15.33068,34.5769-13.58729a67.21864,67.21864,0,0,1,21.6356,7.34549c3.49529,1.81394,6.88526,3.82475,10.20672,5.93854a65.12975,65.12975,0,0,0,10.01086,5.73884c5.83742,2.3979,13.565,2.21058,17.64248-3.31024,3.804-5.15057.86416-13.72537-5.49824-15.17338-6.02963-1.37231-11.31193,4.2985-10.071,10.2278.63481,3.03305,2.91871,6.0012,4.68657,8.48033A61.28956,61.28956,0,0,0,424.85622,299.96c2.35588.11654,2.34973-3.54622,0-3.66246Z"
          transform="translate(-291.87115 -135.5)"
          fill="#ccc"
        />
        <path
          d="M847.21786,764.5h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
          transform="translate(-291.87115 -135.5)"
          fill="#3f3d56"
        />
      </svg>
    </>
  );
};

export default Success;
