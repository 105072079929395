const status = {
  0: "Deletado",
  1: "Inativo",
  5: "Aguardando Pagamento",
  10: "Ativo",
};

export const statusList = (stats) => {
  return status[stats] || "";
};
