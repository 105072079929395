import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getAccess = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/site/get-access`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getValidToken = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/check`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`,
      },
    });
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useAccessFetchRequest() {
  return useQuery(["adminAccess"], () => getAccess(), {
    onSuccess: (data) => {
      return data.data.data;
    },
  });
}

export function useCheck() {
  return useQuery(["check"], () => getValidToken(), {
    onSuccess: (data) => {
      return data.data;
    },
    onError: (err) => {
      console.log("err: ", err);
    },
  });
}
