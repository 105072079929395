import { Button, Card, Modal, notification } from "antd";
import React, { useState } from "react";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { moneyFormat } from "../../helpers/money";
import { statusList } from "../../helpers/status";
import { useProfileFetch } from "../../hooks/profile/useProfile.query";
import Apport from "../Apport";
import { StyledTable } from "./styledTable";

const Indicated = () => {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [modalOpen, setModalOpen] = useState(false);
  const [idIndicate, setIdIndicate] = useState(0);

  const { data } = useProfileFetch();
  const profile = data?.data;

  const handleOpenModalDeposit = (indicated_id) => {
    setModalOpen(true);
    setIdIndicate(indicated_id);
  };
  const requestDeposit = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/apport/aporte-provisorio`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(requestDeposit, {
    onSuccess: (data) => {
      if (data.status === 200) {
        api.success({
          message: "Sucesso.",
          description: "Deposito realizado.",
        });
        setTimeout(1500);
        setModalOpen(false);
        queryClient.invalidateQueries("profile");
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });
  const handleSubmitDeposit = (dataSubmit) => {
    mutateAsync(dataSubmit);
  };

  const columns = [
    // {
    //   title: "Posição",
    //   dataIndex: "position",
    //   key: "position",
    //   width: "10%",
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Referral",
      dataIndex: "referral",
      key: "referral",
      width: "30%",
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      width: "10%",
      render: (amount) => moneyFormat(amount),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "15%",
    },
    {
      title: "Depositar",
      dataIndex: "deposit",
      key: "deposit",
      width: "10%",
      render: (indicated_id) => (
        <>
          <Button onClick={() => handleOpenModalDeposit(indicated_id)}>
            Enviar
          </Button>
        </>
      ),
    },
  ];

  const dataUser = profile?.referral.map((item, index) => {
    return {
      key: index + 1,
      position: item.id,
      name: item?.referral_link,
      referral: item?.referral_link,
      amount: item?.apport,
      status: statusList(item?.status),
      deposit: item?.indicated_id,
    };
  });
  return (
    <div className="layout-content">
      {contextHolder}
      <Card title="Indicados" className="">
        <StyledTable
          tableLayout="fixed"
          size="small"
          columns={columns}
          className=""
          rowKey="uid"
          dataSource={dataUser}
          pagination={false}
        />
      </Card>

      <Modal
        title="Basic Modal"
        open={modalOpen}
        destroyOnClose={true}
        onCancel={() => setModalOpen(false)}
        footer={false}
      >
        <Apport
          onSubmit={handleSubmitDeposit}
          isLoading={isLoading}
          id={idIndicate}
        />
      </Modal>
    </div>
  );
};

export default Indicated;
