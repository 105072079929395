import { EditOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Avatar, Card, List, Modal, notification } from "antd";
import Meta from "antd/es/card/Meta";
import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import imageProfile from "../../assets/images/face-6.jpeg";
import imageCover from "../../assets/images/pattern-tree.svg";
import FormProfile from "./FormProfile";

const StyledAvatarMeta = styled(Avatar)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30px;

  &.ant-avatar {
    width: 120px;
    height: 120px;
  }
`;
const StyledMeta = styled(Meta)`
  display: flex;
  flex-direction: column-reverse;
  .ant-card-meta-title {
    margin: 0px 0;

    h6 {
      font-size: 1rem;
      margin-bottom: -5px;
    }
    h5 {
      font-size: 1.6rem;
    }
  }
`;

const MyProfile = ({ data }) => {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateProfile = async (dataProfile) => {
    const response = await axios.put(
      `${process.env.REACT_APP_API}/v1/user/update-profile?id=${data?.id}`,
      dataProfile,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(updateProfile, {
    onSuccess: (data) => {
      queryClient.invalidateQueries("profile");
      if (data.status === 200) {
        api.success({
          message: "Sucesso.",
          description: "Alterado com sucesso.",
        });
        setTimeout(800);
        setIsModalOpen(false);
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSubmitForm = (e) => {
    mutateAsync(e);
  };

  return (
    <>
      {contextHolder}
      <Card
        className="dark"
        cover={
          <img
            style={{
              objectFit: "cover",
            }}
            height={120}
            alt="example"
            src={imageCover}
          />
        }
      >
        <div
          onClick={showModal}
          style={{
            textAlign: "right",
            cursor: "pointer",
          }}
        >
          <EditOutlined style={{ fontSize: "19px" }} />
        </div>
        <StyledMeta
          title={
            <>
              <h6>Bem vindo</h6>
              <h5>
                {data?.profile.first_name} {data?.profile.last_name}
              </h5>
            </>
          }
          avatar={<StyledAvatarMeta src={imageProfile} />}
        />
        <hr />
        <List
          itemLayout="horizontal"
          dataSource={[
            {
              title: "Telefone",
              description: `${data?.profile.phone} / ${data?.profile.whatsapp}`,
            },
            { title: "E-mail", description: data?.profile.email },
            {
              title: "Endereço",
              description: "Rua das Flores, 234 - Apartamento 1032",
            },
            { title: "Data de nascimento", description: "21/01/1990" },
            { title: "Cidade", description: "São Paulo" },
            { title: "Estado", description: "SP" },
          ]}
          renderItem={(item, index) => (
            <List.Item key={index}>
              <List.Item.Meta
                title={item.title}
                description={item.description}
              />
            </List.Item>
          )}
        />
      </Card>

      <Modal
        open={isModalOpen}
        width={700}
        footer={false}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <FormProfile
          onSubmit={handleSubmitForm}
          data={data}
          loading={isLoading}
        />
      </Modal>
    </>
  );
};

export default MyProfile;
