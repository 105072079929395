import { Table } from "antd";
import React from "react";
import { statusList } from "../../helpers/status";

const column = [
  {
    title: "Posição",
    dataIndex: "position",
    key: "position",
    width: "10%",
  },
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Referral",
    dataIndex: "referral",
    key: "referral",
    width: "30%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    width: "15%",
  },
];

const Direct = ({ data }) => {
  const dataUser = data?.map((item, index) => {
    return {
      key: index + 1,
      position: item?.profile?.position,
      name: item?.profile?.first_name || "Sem Nome",
      referral: item?.profile?.referral_link,
      status: statusList(item?.status),
    };
  });
  return (
    <>
      <h2>Indicados diretos</h2>
      <Table
        tableLayout="fixed"
        size="small"
        columns={column}
        rowKey="uid"
        dataSource={dataUser}
        pagination={true}
      />
    </>
  );
};

export default Direct;
