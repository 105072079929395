import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getProfile = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/user/profile`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getReferralCode = async (code) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/user/get-referral?code=${code}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getGain = async (id) => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/indication/view?id=${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const putProfile = async ({ id, values }) => {
  try {
    const data = await axios.put(
      `${process.env.REACT_APP_API}/v1/user/update-profile?id=${id}`,
      values,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useProfileFetch() {
  return useQuery(["profile"], () => getProfile(), {
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useReferralCodeFetch(code) {
  return useQuery(["referral-code"], () => getReferralCode(code), {
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useGainFetch(id) {
  return useQuery(["gain"], () => getGain(id), {
    onSuccess: (data) => {
      return data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useProfileUpdateRequest(data) {
  return putProfile(data);
}
