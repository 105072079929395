import { useQuery } from "@tanstack/react-query";
import axios from "axios";

const getTotalWithdraw = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/withdraw/my-total`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getAvailableWithdraw = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/withdraw/available`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getWithdraw = async () => {
  try {
    const data = await axios.get(`${process.env.REACT_APP_API}/v1/withdraw`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem(
          process.env.REACT_APP_ACCESS_TOKEN
        )}`,
      },
    });

    return data;
  } catch (error) {
    console.log(error.message);
  }
};

const getWithdrawPending = async () => {
  try {
    const data = await axios.get(
      `${process.env.REACT_APP_API}/v1/withdraw/pending`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );
    console.log(">>>>>", data);
    return data;
  } catch (error) {
    console.log(error.message);
  }
};

export function useTotalWithdrawFetch() {
  return useQuery(["total-withdraw"], () => getTotalWithdraw(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useAvailableWithdrawFetch() {
  return useQuery(["available-withdraw"], () => getAvailableWithdraw(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}

export function useWithdrawFetch() {
  return useQuery(["withdraw"], () => getWithdraw(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}
export function useWithdrawPendingFetch() {
  return useQuery(["pending-withdraw"], () => getWithdrawPending(), {
    retry: 5,
    onSuccess: (data) => {
      return data.data;
    },
    onError: (error) => {
      console.log(error);
    },
    onSettled: () => {
      console.log("onSettled");
    },
  });
}
