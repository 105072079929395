import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "../layout/MainLayout";
import Apport from "../pages/Apport";
import Dashboard from "../pages/Dashboard";
import Error404 from "../pages/Error/404";
import Gains from "../pages/Gains";
import Indicated from "../pages/Indicated";
import Join from "../pages/Join";
import Login from "../pages/Login";
import Profile from "../pages/Profile";
import Referral from "../pages/Profile/referral";
import Withdraw from "../pages/Withdraw";

export default function MainRouter() {
  return useRoutes([
    {
      path: "/",
      element: <MainLayout />,
      children: [
        { element: <Navigate to="/" /> },
        { path: "/", element: <Dashboard /> },
        { path: "perfil", element: <Profile /> },
        { path: "indicados", element: <Indicated /> },
        { path: "ganhos", element: <Gains /> },
        { path: "indicar/:referral", element: <Referral /> },
        { path: "saque", element: <Withdraw /> },
        { path: "aporte", element: <Apport /> },
      ],
    },
    {
      path: "cadastrar",
      element: <Join />,
    },
    {
      path: "login",
      element: <Login />,
    },
    { path: "*", element: <Error404 /> },
  ]);
}
