import { Card } from "antd";
import React from "react";
import { useBankFetchRequest } from "../../hooks/bank/useBank.query";

const AccountBank = () => {
  const { data } = useBankFetchRequest();
  const banks = data?.data;

  return (
    <>
      {banks?.map((bank, index) => {
        return (
          <Card
            title={bank?.account_name}
            bordered={false}
            style={{ margin: "10px 0" }}
            key={index}
          >
            {bank?.account_type === 1 && (
              <ul>
                <li>Banco: {bank?.bank_name}</li>
                <li>Tipo de Chave: {bank?.type_key}</li>
                <li>chave: {bank?.pix_key}</li>
              </ul>
            )}
            {bank?.account_type === 2 && (
              <ul>
                <li>Banco: {bank?.bank_name}</li>
                <li>Conta: {bank?.account_number}</li>
                <li>Agência: {bank?.bank_agency}</li>
              </ul>
            )}
          </Card>
        );
      })}
    </>
  );
};

export default AccountBank;
