import { Button, Divider, Form, Input } from "antd";
import React, { useState } from "react";
import IntlCurrencyInput from "react-intl-currency-input";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const Apport = ({ onSubmit, isLoading, id = "" }) => {
  const [formAmountToDeposit, setFormAmountToDeposit] = useState(0);

  const handleChangeAmount = (event, value) => {
    event.preventDefault();
    setFormAmountToDeposit(value);
  };

  const submitFormDeposit = (e) => {
    const data = {
      amount: formAmountToDeposit,
      ...e,
    };
    onSubmit(data);
  };

  return (
    <>
      <Divider />
      <Form
        name="basic"
        onFinish={submitFormDeposit}
        // onFinishFailed={onFinishFailed}
        className="row-col"
        layout="vertical"
        initialValues={{
          user_id: id,
        }}
      >
        <IntlCurrencyInput
          component={Input}
          currency="BRL"
          config={currencyConfig}
          className="ant-input css-dev-only-do-not-override-1322foz"
          onChange={handleChangeAmount}
        />
        {id && (
          <Form.Item name="user_id">
            <Input
              placeholder="Primeiro Nome"
              style={{
                height: "50px",
              }}
              hidden
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            style={{ width: "100%", marginTop: "15px" }}
            type="primary"
            htmlType="submit"
            loading={isLoading}
          >
            Depositar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Apport;
