import { Button, Form, Input, Radio, Select, Typography } from "antd";
import React, { useState } from "react";
import styled from "styled-components";

const { Title } = Typography;

const StyledForm = styled(Form)`
  gap: 30px;
  input {
    background: #0f0f0f;
    border-color: #0e111b77;
  }
`;

const bankList = [
  {
    value: "BANCO DO BRASIL",
    label: "001 - BANCO DO BRASIL",
  },
  {
    value: "ITAÚ",
    label: "341 - ITAÚ",
  },
  {
    value: "CAIXA ECONÔMICA FEDERAL",
    label: "104 - CAIXA ECONÔMICA FEDERAL",
  },
  {
    value: "BANCO SANTANDER S.A.",
    label: "033 - BANCO SANTANDER S.A.",
  },
  {
    value: "BRB - BANCO DE BRASÍLIA",
    label: "070 - BRB - BANCO DE BRASÍLIA",
  },
  {
    value: "BANCO INTER",
    label: "077 - BANCO INTER",
  },
  {
    value: "BRADESCO",
    label: "237 - BRADESCO",
  },
  {
    value: "CITIBANK",
    label: "745 - CITIBANK",
  },
  {
    value: "BANCO SAFRA",
    label: "422 - BANCO SAFRA",
  },
  {
    value: "BANCO HSBC",
    label: "399 - BANCO HSBC",
  },
  {
    value: "BANCOOB",
    label: "756 - BANCOOB",
  },
  {
    value: "BANCO ORIGINAL",
    label: "212 - BANCO ORIGINAL",
  },
  {
    value: "BANCO CENTRAL DO BRASIL",
    label: "002 - BANCO CENTRAL DO BRASIL",
  },
  {
    value: "BANCO DA AMAZONIA S.A",
    label: "003 - BANCO DA AMAZONIA S.A",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "004 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "007 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO MERIDIONAL DO BRASIL",
    label: "008 - BANCO MERIDIONAL DO BRASIL",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "020 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO S.A",
    label: "021 - BANCO DO ESTADO DO ESPIRITO SANTO S.A",
  },
  {
    value: "BANCO DE CREDITO REAL DE MINAS GERAIS SA",
    label: "022 - BANCO DE CREDITO REAL DE MINAS GERAIS SA",
  },
  {
    value: "BANCO DO ESTADO DE PERNAMBUCO",
    label: "024 - BANCO DO ESTADO DE PERNAMBUCO",
  },
  {
    value: "BANCO ALFA S/A",
    label: "025 - BANCO ALFA S/A",
  },
  {
    value: "BANCO DO ESTADO DO ACRE S.A",
    label: "026 - BANCO DO ESTADO DO ACRE S.A",
  },
  {
    value: "BANCO DO ESTADO DE SANTA CATARINA S.A",
    label: "027 - BANCO DO ESTADO DE SANTA CATARINA S.A",
  },
  {
    value: "BANCO DO ESTADO DA BAHIA S.A",
    label: "028 - BANCO DO ESTADO DA BAHIA S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO DE JANEIRO S.A",
    label: "029 - BANCO DO ESTADO DO RIO DE JANEIRO S.A",
  },
  {
    value: "BANCO DO ESTADO DA PARAIBA S.A",
    label: "030 - BANCO DO ESTADO DA PARAIBA S.A",
  },
  {
    value: "BANCO DO ESTADO DE GOIAS S.A",
    label: "031 - BANCO DO ESTADO DE GOIAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MATO GROSSO S.A.",
    label: "032 - BANCO DO ESTADO DO MATO GROSSO S.A.",
  },
  {
    value: "BANCO DO ESADO DO AMAZONAS S.A",
    label: "034 - BANCO DO ESADO DO AMAZONAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO CEARA S.A.",
    label: "035 - BANCO DO ESTADO DO CEARA S.A.",
  },
  {
    value: "BANCO DO ESTADO DE MINAS GERAIS S.A",
    label: "036 - BANCO DO ESTADO DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MARANHAO S.A",
    label: "037 - BANCO DO ESTADO DO MARANHAO S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARA S.A",
    label: "038 - BANCO DO ESTADO DO PARA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARANA S.A",
    label: "039 - BANCO DO ESTADO DO PARANA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PIAUI S.A",
    label: "041 - BANCO DO ESTADO DO PIAUI S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "042 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
    label: "043 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO ETC",
    label: "044 - BANCO DO ESTADO DO ESPIRITO SANTO ETC",
  },
  {
    value: "BANCO DO NORTE S.A",
    label: "045 - BANCO DO NORTE S.A",
  },
  {
    value: "BANCO DO ESTADO DE RORAIMA S.A",
    label: "046 - BANCO DO ESTADO DE RORAIMA S.A",
  },
  {
    value: "BANCO DE PERNAMBUCO S.A",
    label: "047 - BANCO DE PERNAMBUCO S.A",
  },
  {
    value: "BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
    label: "048 - BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "049 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "051 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARÁ S.A",
    label: "052 - BANCO DO ESTADO DO PARÁ S.A",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "054 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO DA LAVOURA DE MINAS GERAIS S.A",
    label: "062 - BANCO DA LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
    label: "063 - BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
  },
  {
    value: "BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
    label: "065 - BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
  },
  {
    value: "BANCO DA BAHIA",
    label: "066 - BANCO DA BAHIA",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "069 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "070 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "072 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "BANCO DE LAVOURA DE MINAS GERAIS S.A",
    label: "073 - BANCO DE LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "074 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO DE LAVOURA E CREDITO DE SAO PAULO",
    label: "075 - BANCO DE LAVOURA E CREDITO DE SAO PAULO",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "077 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO INTERMEDIUM S.A",
    label: "077-5 - BANCO INTERMEDIUM S.A",
  },
  {
    value: "BANCO INTER",
    label: "077-6 - BANCO INTER",
  },
  {
    value: "BRADESCO",
    label: "237 - BRADESCO",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "103 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "CITIBANK",
    label: "745 - CITIBANK",
  },
  {
    value: "BANCO SAFRA",
    label: "422 - BANCO SAFRA",
  },
  {
    value: "BANCO HSBC",
    label: "399 - BANCO HSBC",
  },
  {
    value: "BANCOOB",
    label: "756 - BANCOOB",
  },
  {
    value: "BANCO ORIGINAL",
    label: "212 - BANCO ORIGINAL",
  },
  {
    value: "BANCO CENTRAL DO BRASIL",
    label: "002 - BANCO CENTRAL DO BRASIL",
  },
  {
    value: "BANCO DA AMAZONIA S.A",
    label: "003 - BANCO DA AMAZONIA S.A",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "004 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "007 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO MERIDIONAL DO BRASIL",
    label: "008 - BANCO MERIDIONAL DO BRASIL",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "020 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO S.A",
    label: "021 - BANCO DO ESTADO DO ESPIRITO SANTO S.A",
  },
  {
    value: "BANCO DE CREDITO REAL DE MINAS GERAIS SA",
    label: "022 - BANCO DE CREDITO REAL DE MINAS GERAIS SA",
  },
  {
    value: "BANCO DO ESTADO DE PERNAMBUCO",
    label: "024 - BANCO DO ESTADO DE PERNAMBUCO",
  },
  {
    value: "BANCO ALFA S/A",
    label: "025 - BANCO ALFA S/A",
  },
  {
    value: "BANCO DO ESTADO DO ACRE S.A",
    label: "026 - BANCO DO ESTADO DO ACRE S.A",
  },
  {
    value: "BANCO DO ESTADO DE SANTA CATARINA S.A",
    label: "027 - BANCO DO ESTADO DE SANTA CATARINA S.A",
  },
  {
    value: "BANCO DO ESTADO DA BAHIA S.A",
    label: "028 - BANCO DO ESTADO DA BAHIA S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO DE JANEIRO S.A",
    label: "029 - BANCO DO ESTADO DO RIO DE JANEIRO S.A",
  },
  {
    value: "BANCO DO ESTADO DA PARAIBA S.A",
    label: "030 - BANCO DO ESTADO DA PARAIBA S.A",
  },
  {
    value: "BANCO DO ESTADO DE GOIAS S.A",
    label: "031 - BANCO DO ESTADO DE GOIAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MATO GROSSO S.A.",
    label: "032 - BANCO DO ESTADO DO MATO GROSSO S.A.",
  },
  {
    value: "BANCO DO ESADO DO AMAZONAS S.A",
    label: "034 - BANCO DO ESADO DO AMAZONAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO CEARA S.A.",
    label: "035 - BANCO DO ESTADO DO CEARA S.A.",
  },
  {
    value: "BANCO DO ESTADO DE MINAS GERAIS S.A",
    label: "036 - BANCO DO ESTADO DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MARANHAO S.A",
    label: "037 - BANCO DO ESTADO DO MARANHAO S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARA S.A",
    label: "038 - BANCO DO ESTADO DO PARA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARANA S.A",
    label: "039 - BANCO DO ESTADO DO PARANA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PIAUI S.A",
    label: "041 - BANCO DO ESTADO DO PIAUI S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "042 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
    label: "043 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO ETC",
    label: "044 - BANCO DO ESTADO DO ESPIRITO SANTO ETC",
  },
  {
    value: "BANCO DO NORTE S.A",
    label: "045 - BANCO DO NORTE S.A",
  },
  {
    value: "BANCO DO ESTADO DE RORAIMA S.A",
    label: "046 - BANCO DO ESTADO DE RORAIMA S.A",
  },
  {
    value: "BANCO DE PERNAMBUCO S.A",
    label: "047 - BANCO DE PERNAMBUCO S.A",
  },
  {
    value: "BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
    label: "048 - BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "049 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "051 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARÁ S.A",
    label: "052 - BANCO DO ESTADO DO PARÁ S.A",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "054 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO DA LAVOURA DE MINAS GERAIS S.A",
    label: "062 - BANCO DA LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
    label: "063 - BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
  },
  {
    value: "BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
    label: "065 - BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
  },
  {
    value: "BANCO DA BAHIA",
    label: "066 - BANCO DA BAHIA",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "069 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "070 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "072 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "BANCO DE LAVOURA DE MINAS GERAIS S.A",
    label: "073 - BANCO DE LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "074 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO DE LAVOURA E CREDITO DE SAO PAULO",
    label: "075 - BANCO DE LAVOURA E CREDITO DE SAO PAULO",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "077 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO INTERMEDIUM S.A",
    label: "077-5 - BANCO INTERMEDIUM S.A",
  },
  {
    value: "BANCO INTER",
    label: "077-6 - BANCO INTER",
  },
  {
    value: "BRADESCO",
    label: "237 - BRADESCO",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "103 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "CITIBANK",
    label: "745 - CITIBANK",
  },
  {
    value: "BANCO SAFRA",
    label: "422 - BANCO SAFRA",
  },
  {
    value: "BANCO HSBC",
    label: "399 - BANCO HSBC",
  },
  {
    value: "BANCOOB",
    label: "756 - BANCOOB",
  },
  {
    value: "BANCO ORIGINAL",
    label: "212 - BANCO ORIGINAL",
  },
  {
    value: "BANCO CENTRAL DO BRASIL",
    label: "002 - BANCO CENTRAL DO BRASIL",
  },
  {
    value: "BANCO DA AMAZONIA S.A",
    label: "003 - BANCO DA AMAZONIA S.A",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "004 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "007 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO MERIDIONAL DO BRASIL",
    label: "008 - BANCO MERIDIONAL DO BRASIL",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "020 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO S.A",
    label: "021 - BANCO DO ESTADO DO ESPIRITO SANTO S.A",
  },
  {
    value: "BANCO DE CREDITO REAL DE MINAS GERAIS SA",
    label: "022 - BANCO DE CREDITO REAL DE MINAS GERAIS SA",
  },
  {
    value: "BANCO DO ESTADO DE PERNAMBUCO",
    label: "024 - BANCO DO ESTADO DE PERNAMBUCO",
  },
  {
    value: "BANCO ALFA S/A",
    label: "025 - BANCO ALFA S/A",
  },
  {
    value: "BANCO DO ESTADO DO ACRE S.A",
    label: "026 - BANCO DO ESTADO DO ACRE S.A",
  },
  {
    value: "BANCO DO ESTADO DE SANTA CATARINA S.A",
    label: "027 - BANCO DO ESTADO DE SANTA CATARINA S.A",
  },
  {
    value: "BANCO DO ESTADO DA BAHIA S.A",
    label: "028 - BANCO DO ESTADO DA BAHIA S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO DE JANEIRO S.A",
    label: "029 - BANCO DO ESTADO DO RIO DE JANEIRO S.A",
  },
  {
    value: "BANCO DO ESTADO DA PARAIBA S.A",
    label: "030 - BANCO DO ESTADO DA PARAIBA S.A",
  },
  {
    value: "BANCO DO ESTADO DE GOIAS S.A",
    label: "031 - BANCO DO ESTADO DE GOIAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MATO GROSSO S.A.",
    label: "032 - BANCO DO ESTADO DO MATO GROSSO S.A.",
  },
  {
    value: "BANCO DO ESADO DO AMAZONAS S.A",
    label: "034 - BANCO DO ESADO DO AMAZONAS S.A",
  },
  {
    value: "BANCO DO ESTADO DO CEARA S.A.",
    label: "035 - BANCO DO ESTADO DO CEARA S.A.",
  },
  {
    value: "BANCO DO ESTADO DE MINAS GERAIS S.A",
    label: "036 - BANCO DO ESTADO DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO DO ESTADO DO MARANHAO S.A",
    label: "037 - BANCO DO ESTADO DO MARANHAO S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARA S.A",
    label: "038 - BANCO DO ESTADO DO PARA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARANA S.A",
    label: "039 - BANCO DO ESTADO DO PARANA S.A",
  },
  {
    value: "BANCO DO ESTADO DO PIAUI S.A",
    label: "041 - BANCO DO ESTADO DO PIAUI S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "042 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
    label: "043 - BANCO DO ESTADO DO RIO GRANDE DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DO ESPIRITO SANTO ETC",
    label: "044 - BANCO DO ESTADO DO ESPIRITO SANTO ETC",
  },
  {
    value: "BANCO DO NORTE S.A",
    label: "045 - BANCO DO NORTE S.A",
  },
  {
    value: "BANCO DO ESTADO DE RORAIMA S.A",
    label: "046 - BANCO DO ESTADO DE RORAIMA S.A",
  },
  {
    value: "BANCO DE PERNAMBUCO S.A",
    label: "047 - BANCO DE PERNAMBUCO S.A",
  },
  {
    value: "BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
    label: "048 - BANCO DO ESTADO DE MATO GROSSO DO SUL S.A",
  },
  {
    value: "BANCO DO ESTADO DE ALAGOAS S.A",
    label: "049 - BANCO DO ESTADO DE ALAGOAS S.A",
  },
  {
    value: "BANCO DO ESTADO DE SERGIPE S.A",
    label: "051 - BANCO DO ESTADO DE SERGIPE S.A",
  },
  {
    value: "BANCO DO ESTADO DO PARÁ S.A",
    label: "052 - BANCO DO ESTADO DO PARÁ S.A",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "054 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO DA LAVOURA DE MINAS GERAIS S.A",
    label: "062 - BANCO DA LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
    label: "063 - BANCO BANDEIRANTES DO ESTADO DE SAO PAULO",
  },
  {
    value: "BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
    label: "065 - BANCO DE DESENVOLVIMENTO DO RIO GRANDE DO SUL",
  },
  {
    value: "BANCO DA BAHIA",
    label: "066 - BANCO DA BAHIA",
  },
  {
    value: "BANCO DE CREDITO NACIONAL S.A",
    label: "069 - BANCO DE CREDITO NACIONAL S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "070 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "072 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "BANCO DE LAVOURA DE MINAS GERAIS S.A",
    label: "073 - BANCO DE LAVOURA DE MINAS GERAIS S.A",
  },
  {
    value: "BANCO NAC DESENV. ECO. SOCIAL S.A",
    label: "074 - BANCO NAC DESENV. ECO. SOCIAL S.A",
  },
  {
    value: "BANCO DE LAVOURA E CREDITO DE SAO PAULO",
    label: "075 - BANCO DE LAVOURA E CREDITO DE SAO PAULO",
  },
  {
    value: "BANCO DO NORDESTE DO BRASIL S.A",
    label: "077 - BANCO DO NORDESTE DO BRASIL S.A",
  },
  {
    value: "BANCO INTERMEDIUM S.A",
    label: "077-5 - BANCO INTERMEDIUM S.A",
  },
  {
    value: "BANCO INTER",
    label: "077-6 - BANCO INTER",
  },
  {
    value: "BRADESCO",
    label: "237 - BRADESCO",
  },
  {
    value: "BANCO SUDAMERIS BRASILEIRO S.A",
    label: "103 - BANCO SUDAMERIS BRASILEIRO S.A",
  },
  {
    value: "CITIBANK",
    label: "745 - CITIBANK",
  },
  {
    value: "BANCO SAFRA",
    label: "422 - BANCO SAFRA",
  },
  {
    value: "BANCO HSBC",
    label: "399 - BANCO HSBC",
  },
  {
    value: "BANCOOB",
    label: "756 - BANCOOB",
  },
  {
    value: "BANCO ORIGINAL",
    label: "212 - BANCO ORIGINAL",
  },
  {
    value: "STONE PAGAMENTOS S.A",
    label: "057 - STONE PAGAMENTOS S.A",
  },
];
const CreateBank = ({ onSubmit, loading }) => {
  const [form] = Form.useForm();
  const [value, setValue] = useState(1);
  const [typeAccount, setTypeAccount] = useState(1);

  const onChangeBank = (value) => {
    console.log(`selected ${value}`);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeType = (e) => {
    setValue(e.target.value);
    setTypeAccount(e.target.value);
  };

  return (
    <StyledForm
      // {...layout}
      layout="vertical"
      form={form}
      name="control-hooks"
      onFinish={onSubmit}
    >
      {/* {contextHolder} */}

      <Form.Item label="Tipo de conta" name="account_type">
        <Radio.Group
          buttonStyle="solid"
          onChange={onChangeType}
          value={value}
          style={{ marginBottom: "20px" }}
        >
          <Radio.Button value="1">PIX</Radio.Button>
          <Radio.Button value="2">Conta Corrente</Radio.Button>
        </Radio.Group>
      </Form.Item>

      {typeAccount === "1" || typeAccount === "2" ? (
        <Form.Item
          name="account_name"
          label="Nome para conta"
          rules={[
            {
              required: true,
            },
          ]}
          style={{
            width: "100%",
          }}
        >
          <Input />
        </Form.Item>
      ) : (
        ""
      )}

      {/* CONTA */}
      {typeAccount === "1" && (
        <>
          <Form.Item name="type_key" label="Tipo de chave">
            <Select
              style={{
                width: "100%",
              }}
              bordered={true}
              options={[
                {
                  value: "cpf",
                  label: "CPF",
                },
                {
                  value: "celular",
                  label: "Celular",
                },
                {
                  value: "email",
                  label: "Email",
                },
                {
                  value: "aleatoria",
                  label: "Chave Aleatória",
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="pix_key"
            label="Chave"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              width: "100%",
            }}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {typeAccount === "2" && (
        <>
          <Form.Item name="bank_name" label="Tipo de chave">
            <Select
              showSearch
              placeholder="Selecione o banco"
              optionFilterProp="children"
              onChange={onChangeBank}
              onSearch={onSearch}
              filterOption={filterOption}
              options={bankList}
              style={{ width: "100%", marginBottom: "10px" }}
            />
          </Form.Item>
          <Form.Item
            name="account_number"
            label="Conta"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              width: "100%",
            }}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="bank_agency"
            label="Agência"
            rules={[
              {
                required: true,
              },
            ]}
            style={{
              width: "100%",
            }}
          >
            <Input />
          </Form.Item>
        </>
      )}
      {typeAccount === "1" || typeAccount === "2" ? (
        <Form.Item>
          <Button loading={loading} type="primary" htmlType="submit">
            Salvar
          </Button>
        </Form.Item>
      ) : (
        ""
      )}
    </StyledForm>
  );
};

export default CreateBank;
