import { Layout } from "antd";
import React from "react";

const { Header, Footer, Sider, Content } = Layout;

const ContentCard = ({ children }) => {
  return (
    <Content
      style={{
        padding: 24,
        margin: "0 auto",
        minHeight: 280,
        background: "#eeeeee00",
        width: "50%",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      {children}
    </Content>
  );
};

export default ContentCard;
