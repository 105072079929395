export const moneyFormat = (amount = 0, currency = "USD") => {
  const amounts = parseFloat(amount);

  const locale = currency === "USD" ? "pt-BR" : "pt-BR";
  const localeCurrency = currency === "USD" ? "USD" : "BRL";
  const formattedAmount = amounts.toLocaleString("pt-BR", {
    style: "currency",
    currency: localeCurrency,
  });

  return formattedAmount;
};
