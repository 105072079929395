import { Col, Row } from "antd";
import React from "react";

const Title = ({ text }) => {
  return (
    <Row gutter={[24, 5]}>
      <Col className="mb-24" xs={24}>
        <h2>{text}</h2>
      </Col>
    </Row>
  );
};

export default Title;
