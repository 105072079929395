import {
  Button,
  Card,
  Col,
  Divider,
  Input,
  Layout,
  QRCode,
  Row,
  Space,
  Typography,
} from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import FormJoin from "../../components/FormJoin";
import useClipboard from "../../helpers/useClipboard";

const { Header, Content, Footer } = Layout;

const Referral = () => {
  const copyToClipboard = useClipboard();
  let { referral } = useParams();
  return (
    <Card title="Indicar Usuario" className="">
      <Row gutter={[24, 5]}>
        <Col xs={24} sm={24} md={13} lg={13} xl={13} className="mb-24">
          <FormJoin
            // onSubmit={handleSubmitForm}
            referral={referral}
            // sending={isLoading}
          />
        </Col>
        <Col xs={24} sm={24} md={1} lg={1} xl={1} className="mb-24">
          <div className="verticalDivider"></div>
        </Col>
        <Col xs={24} sm={24} md={10} lg={10} xl={10} className="mb-24">
          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",
            }}
          >
            <Typography.Title level={5}>
              Copiar link de indicação
            </Typography.Title>
            <Space.Compact
              style={{
                width: "100%",
              }}
            >
              <Input
                disabled
                defaultValue={`https://backoffice.espheratrading.com/cadastrar?referral=${referral}`}
              />
              <Button
                type="primary"
                onClick={() =>
                  copyToClipboard(
                    `https://backoffice.espheratrading.com/cadastrar?referral=${referral}`
                  )
                }
              >
                Copiar
              </Button>
            </Space.Compact>
          </Space>
          <Divider />
          <Space
            direction="vertical"
            size="large"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h2>Escaneie o Qr Code</h2>
            <QRCode
              bgColor="#000"
              size={350}
              errorLevel="H"
              value={`https://backoffice.espheratrading.com/cadastrar?referral=${referral}`}
              // icon="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
            />
          </Space>
        </Col>
      </Row>
    </Card>
  );
};

export default Referral;
