import { useMutation } from "@tanstack/react-query";
import { Button, Card, Form, Input, Layout, notification } from "antd";
import axios from "axios";
import React from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import bg from "../../assets/images/patterns/loginbg.svg";
import { useCheck } from "../../hooks/access/useAccess.query";

const { Header, Content, Footer } = Layout;

const Login = () => {
  const navigate = useNavigate();
  const { data, isLoading: loadingCheck } = useCheck();
  if (!loadingCheck && data?.data === true) {
    navigate("/");
  }
  const [form] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();

  const loginUser = async (user) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/site/login`,
      user,
      {
        headers: {
          "Content-Type": "application/json",
        },
        validateStatus: function (status) {
          return true;
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(loginUser, {
    onSuccess: (data) => {
      if (data.status === 200) {
        localStorage.setItem(
          process.env.REACT_APP_ACCESS_TOKEN,
          data.data.token.token
        );
        api.success({
          message: "Sucesso.",
          description: "redirecionando ao painel.",
        });
        setTimeout(1500);
        navigate("/");
      }
      if (data.status !== 200) {
        api.error({
          message: "Erro.",
          description: data.message,
        });
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });

  const onFinish = (values) => {
    mutateAsync(values);
  };

  return (
    <div
      className="layout-default layout-sign-up"
      style={{
        position: "fixed",
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        backgroundImage: `url(${bg})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "right",
      }}
    >
      {contextHolder}
      <Content className="p-0">
        <Card
          className="card-signup header-solid ant-card pt-5"
          title={<img src={logo} alt="" style={{ height: "120px" }} />}
          bordered="false"
        >
          <div className="content">
            <p className="text-lg" style={{ textAlign: "left" }}>
              Bem vindo de volta.
            </p>
          </div>

          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            // onFinishFailed={onFinishFailed}
            className="row-col"
            layout="vertical"
          >
            <Form.Item
              name="username"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input
                placeholder="Primeiro Nome"
                style={{
                  height: "50px",
                }}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Please input your username!" },
              ]}
            >
              <Input.Password
                placeholder="Senha"
                style={{
                  borderRadius: "6px",
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
                loading={isLoading}
              >
                Entrar
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Content>
    </div>
  );
};

export default Login;
