import {
  DiffOutlined,
  DollarOutlined,
  HomeOutlined,
  LogoutOutlined,
  UserAddOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.png";
import { useProfileFetch } from "../hooks/profile/useProfile.query";
import { logout } from "../services/auth";

const Sidenav = (props) => {
  const { color } = props;
  let { pathname } = useLocation();
  const page = pathname.split("/");
  pathname = pathname.replace("/", "");

  const { data, isLoading } = useProfileFetch();
  const profile = data?.data;

  const MenuItems = [
    {
      title: "Início",
      link: "/",
      icon: <HomeOutlined />,
    },
    {
      title: "Meu perfil",
      link: "perfil",
      icon: <UserOutlined />,
    },
    {
      title: "Indicados",
      link: "indicados",
      icon: <UsergroupAddOutlined />,
    },
    {
      title: isLoading ? "Loading..." : "Cadastrar",
      link: isLoading ? "#" : `indicar/${profile?.profile?.referral_link}`,
      icon: <UserAddOutlined />,
    },
    {
      title: "Saque",
      link: "saque",
      icon: <DollarOutlined />,
    },
    {
      title: "Ticket",
      link: "ticket",
      icon: <DiffOutlined />,
    },
    {
      title: (
        <Link
          onClick={() => {
            logout();
          }}
        >
          Sair
        </Link>
      ),
      link: "sair",
      icon: <LogoutOutlined />,
    },
  ];

  let activePage = null;
  if (page.length === 1) {
    activePage = page[0];
  }
  if (page.length > 1) {
    activePage = page[1];
  }

  return (
    <>
      <div
        className="brand"
        style={{ display: "flex", justifyContent: "center" }}
      >
        <img src={logo} alt="" style={{ height: "60px" }} />
      </div>
      <hr />
      <Menu theme="dark" mode="vertical">
        {MenuItems.map((menu, index) => {
          return (
            <Menu.Item key={index}>
              <NavLink to={`${menu.link}`}>
                <span
                  className="icon"
                  style={{
                    background: activePage === `${menu.link}` ? color : "",
                  }}
                >
                  {menu.icon}
                </span>
                <span className="label">{menu.title}</span>
              </NavLink>
            </Menu.Item>
          );
        })}
      </Menu>
    </>
  );
};

export default Sidenav;
