import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Card, Col, Divider, Modal, Row, notification } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useProfileFetch } from "../../hooks/profile/useProfile.query";
import AccountBank from "./AccountBank";
import AddressProfile from "./AddressProfile";
import CreateBank from "./CreateBank";
import MyProfile from "./MyProfile";

const Profile = () => {
  const queryClient = useQueryClient();
  const [api, contextHolder] = notification.useNotification();
  const [isModalBankOpen, setIsModalBankOpen] = useState(false);

  const createBank = async (dataBank) => {
    const response = await axios.post(
      `${process.env.REACT_APP_API}/v1/bank/create`,
      dataBank,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(
            process.env.REACT_APP_ACCESS_TOKEN
          )}`,
        },
      }
    );

    return response.data;
  };
  const { isLoading, mutateAsync } = useMutation(createBank, {
    onSuccess: (data) => {
      if (data.status === 200) {
        api.success({
          message: "Sucesso.",
          description: "Adicionado com sucesso.",
        });
        setTimeout(800);
        setIsModalBankOpen(false);
        queryClient.invalidateQueries("my-banks");
      }
    },
    onError: (error) => {
      api.open({
        message: "Erro",
        description: error,
      });
    },
  });

  const showModalNewBank = () => {
    setIsModalBankOpen(true);
  };
  const handleCancel = () => {
    setIsModalBankOpen(false);
  };

  const handleSubmitFormBank = (e) => {
    mutateAsync(e);
  };

  const { data } = useProfileFetch();
  const profile = data?.data;
  return (
    <div className="layout-content">
      {contextHolder}
      <Card title="Perfil" className="">
        <Row gutter={[10]}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} className="mb-24">
            <MyProfile data={profile} />
            <Divider></Divider>
            <AddressProfile />
          </Col>
          <Col xs={24} sm={24} md={14} lg={14} xl={14} className="">
            <Card bordered={false} className="criclebox dark">
              <Button type="primary" onClick={showModalNewBank}>
                Cadastrar novo
              </Button>
              <AccountBank />
            </Card>
          </Col>
        </Row>
      </Card>

      <Modal
        open={isModalBankOpen}
        width={700}
        footer={false}
        onCancel={handleCancel}
        destroyOnClose={true}
      >
        <CreateBank onSubmit={handleSubmitFormBank} loading={isLoading} />
      </Modal>
    </div>
  );
};

export default Profile;
