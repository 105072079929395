import { Card, Form, Layout, Typography } from "antd";
import React from "react";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import logo from "../../assets/images/logo.png";
import bg from "../../assets/images/patterns/login.jpg";
import FormJoin from "../../components/FormJoin";
import { useReferralCodeFetch } from "../../hooks/profile/useProfile.query";

const { Header, Content, Footer } = Layout;
const { Title } = Typography;

const StyledForm = styled(Form)`
  gap: 30px;
  input {
    background: #1a203599;
    border-color: #0e111b77;
  }
`;

const Join = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const referral = searchParams.get("referral");

  const { data } = useReferralCodeFetch(referral);

  return (
    <>
      {/* <Layout> */}
      <div
        className="layout-default layout-sign-up"
        style={{
          position: "fixed",
          width: "100%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          background: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Content className="p-0">
          <Card
            className="card-signup header-solid h-full ant-card pt-5"
            title={<img src={logo} alt="" style={{ height: "60px" }} />}
            bordered="false"
          >
            <div className="content">
              <p className="text-lg" style={{ textAlign: "left" }}>
                Você esta sendo convidado por:{" "}
                <span style={{ fontSize: "1.6rem" }}> {data?.data.name}</span>
              </p>
            </div>
            {/* form */}
            <FormJoin referral={referral} />
            {/* form */}
            <p className="font-semibold text-muted text-center">
              Já possui uma conta?{" "}
              <Link to="/sign-in" className="font-bold text-success">
                Login
              </Link>
            </p>
          </Card>
        </Content>
      </div>
      {/* </Layout> */}
    </>
  );
};

export default Join;
