import { Button, Form, Input } from "antd";
import React from "react";
import styled from "styled-components";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 14 },
};

const StyledForm = styled(Form)`
  gap: 30px;
  input {
    background: #1a203599;
    border-color: #0e111b77;
  }
`;

const FormProfile = ({ data, onSubmit, loading }) => {
  const [form] = Form.useForm();

  return (
    <StyledForm
      // {...layout}
      layout="vertical"
      form={form}
      name="control-hooks"
      initialValues={data?.profile}
      onFinish={onSubmit}
    >
      <Form.Item
        name="first_name"
        label="Nome"
        rules={[
          {
            required: true,
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="last_name"
        label="Nome"
        rules={[
          {
            required: true,
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Telefone"
        rules={[
          {
            required: true,
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="whatsapp"
        label="Whatsapp"
        rules={[
          {
            required: true,
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        <Input />
      </Form.Item>
      {/* <Form.Item
        name="nacimento"
        label="Data Nacimento"
        rules={[
          {
            required: true,
          },
        ]}
        style={{
          width: "100%",
        }}
      >
        <Input />
      </Form.Item> */}

      <Form.Item>
        <Button loading={loading} type="primary" htmlType="submit">
          Salvar
        </Button>
      </Form.Item>
    </StyledForm>
  );
};

export default FormProfile;
